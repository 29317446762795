import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HomeHero from '../components/heros/homeHero'
import DestinationInnPart from '../components/home/destinationInn'
import NewOwnersPart from '../components/home/newOwners'
import WelcomeToPart from '../components/home/welcomeTo'
import NewRenosPart from '../components/home/newRenos'
import RoomsPart from '../components/home/rooms'
import RatesPart from '../components/home/rates'
import GolfCoursesPart from '../components/home/golfCourses'
import CanadianRockiesPart from '../components/home/canadianRockies'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Home"
      description="Centrally located in Radium Hotsprings, a short walking distance from restaurants, a fitness center bars, &amp; various boutique shops."
      canonical="https://destinationinn.ca"
    />

    <HomeHero />

    <DestinationInnPart
      title="PREMIUM LODGING"
      lastLine="in Radium Hot Springs"
    />

    <NewOwnersPart />

    <WelcomeToPart />

    <NewRenosPart />

    <RoomsPart />

    <RatesPart />

    <GolfCoursesPart />

    <CanadianRockiesPart />
  </Layout>
)

export default IndexPage
