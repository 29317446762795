import * as React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { useState } from 'react'
import Button, { OnClickTrueButton } from '../buttons/buttons'

export default function SearchChoices() {
  const [showChoice, setShowChoice] = useState(false)
  // adding typeof check to help with gatsby build
  if (showChoice && typeof document !== 'undefined') {
    document.body.style.overflowY = 'hidden'
  } else if (typeof document !== 'undefined') {
    document.body.style.overflowY = 'unset'
  }
  //   console.log(showChoice)

  return (
    <>
      {showChoice && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => setShowChoice(false)}
          onKeyDown={() => setShowChoice(false)}
          css={css`
            position: fixed;
            z-index: 9999;

            background: rgba(0, 0, 0, 0.8);

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: grid;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={css`
              position: relative;
              padding: 0.5em;
              background: white;
            `}
          >
            <p
              css={css`
                text-align: center;
                color: var(--blue);
                font-weight: bold;
                ${
                  '' /* important is needed because it's being overwritten elsewhere */
                }
                font-size: 1.5rem !important;
                text-decoration: underline;
                text-decoration-color: var(--red);
              `}
            >
              How would you like to book your room?
            </p>
            <div
              css={css`
                z-index: 10000;
                display: grid;
                place-items: center;
              `}
            >
              <Link to="/search-by-availability/" title="By availability">
                <Button cta="Book by availability" />
              </Link>
              <Link to="/search-by-calendar/" title="By calendar">
                <Button cta="Book by calendar" />
              </Link>
            </div>
            <p
              css={css`
                text-align: center;
                color: var(--blue);
                ${
                  '' /* important is needed because it's being overwritten elsewhere */
                }
                font-size: 14px !important;
              `}
            >
              Call{' '}
              <a
                css={css`
                  ${'' /* margin-inline: 2px; */}
                  color: var(--blue);
                  font-weight: bold;
                  ${
                    '' /* important is needed because it's being overwritten elsewhere */
                  }
                  font-size: 14px !important;
                  text-decoration: underline;
                  text-decoration-color: var(--blue);
                `}
                href="tel:+12503479111"
                title="call us at 1-250-347-9111"
              >
                1-250-347-9111{' '}
              </a>
              if you experience any booking problems.
            </p>
          </div>
        </div>
      )}

      <div
        css={css`
          display: grid;
          justify-content: center;
          align-items: center;
        `}
      >
        <OnClickTrueButton
          cta="Book Online Here"
          onClick={setShowChoice}
          css={css`
            position: relative;
            box-sizing: border-box;
            display: inline-block;

            height: 50px;
            padding: 0 20px;

            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.25rem;
            line-height: 50px;
            text-align: center;
            text-transform: uppercase;
            text-decoration: none;

            background: var(--red);
            color: white;

            background-size: 400%;

            border: none;

            z-index: 1;

            &:hover {
              cursor: pointer;
            }
          `}
        />
      </div>
    </>
  )
}
