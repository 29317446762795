import * as React from "react"
import styled from "@emotion/styled"

const Wrapper = styled.div`
background: var(--dark-blue);
color: white;

padding: 32px 0;

div {
    max-width: var(--site-width);
    margin: 0 auto;
    p {
        text-align: center; font-weight: bold;
        @media(min-width: 768px) {
            font-size: 3rem;
        }
    }
}

`

const NewOwnersPart = () => (
    <>
        <Wrapper>
            <div>
                <p>"New Owners, Fully Renovated in 2021"</p>
            </div>
        </Wrapper>
    </>
)

export default NewOwnersPart
