import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Button from "../buttons/buttons"

const Wrapper = styled.div`
background: var(--grey);
color: var(--other=blue);

padding: 32px 0;

div {
    max-width: var(--site-width);
    margin: 0 auto;
    
    .buttonClass {
        display: flex;
        justify-content: center;
        text-decoration: none;
        margin-top: 32px;
    }

    .styledHTwo {
        text-align: center;
        font-weight: bold;
        color: var(--dark-blue);
        text-decoration: underline;
        text-decoration-color: var(--red);
    
        @media(min-width: 768px) {
            font-size: 3rem;
        }
    }
}



`

const GolfCoursesPart = () => (
    <>
        <Wrapper>
            <div>
                <h2 className="styledHTwo">Golf Courses, Activities, and Tourism Info</h2>
                <Link to="/links" className="buttonClass" title="our links">
                    <Button cta="VIEW LINKS HERE" />
                </Link>
            </div>
        </Wrapper>
    </>
)

export default GolfCoursesPart
