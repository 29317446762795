import * as React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'
// import Button from '../buttons/buttons'
import SearchChoices from '../ReservationKey/SearchChoices'

const Wrapper = styled.div`
  max-width: var(--site-width);
  margin: 64px auto 0 auto;

  .innerWrapper {
    display: grid;
    grid-row-gap: 32px;

    h2 {
      color: var(--blue);
      font-size: 2rem;
      text-decoration: underline;
      text-decoration-color: var(--red);
    }

    p {
      font-size: 18px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      grid-column-gap: 32px;
      margin: 64px auto;
    }
  }

  .buttonClass {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
`

const WelcomeToPart = () => (
  <>
    <Wrapper>
      <div className="innerWrapper">
        <div className="col">
          <h2>What's Your Destination Inn Radium Hot Springs?</h2>
          <p>
            Centrally located with a convenient location, walking distance to
            restaurants, fitness centre, bars, pubs, liquor store, candy store
            &amp; various boutique shops.
          </p>
          <p>
            We welcome you to Radium Hot Springs, BC &amp; hope you enjoy your
            stay. We will provide all you need for your stay in Radium Hot
            Springs. We love to meet new guests and greet them with a smile! We
            pride ourselves on having the best place to stay in the village. To
            make the experience better for our guests, we strive to have all the
            comforts of home, without any of the stress!
          </p>

          {/* --- start --- */}
          {/* This is the old button, leave here for a few pulls until we're sure it's unnecessary */}
          {/* <a className="buttonClass" href="tel:+12503479111" title="call us at 1-250-347-9111">
                        <Button cta="CALL US TO BOOK" />
                    </a> */}
          {/* --- end --- */}
          <SearchChoices />
        </div>
        <div className="col" style={{ display: 'grid' }}>
          <StaticImage
            style={{ gridArea: '1/1' }}
            src="../../components/home/roadway.jpg"
            alt="roadway image placeholder"
            // aspectRatio={16 / 9}
            layout="fullWidth"
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            loading="lazy"
          />
        </div>
      </div>
    </Wrapper>
  </>
)

export default WelcomeToPart
