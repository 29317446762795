import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../buttons/buttons"

const Wrapper = styled.div`
max-width: var(--site-width);
margin: 0 auto;

.innerWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;

    .minusOneRow {
        grid-row: -1;
    }

    .styledP {
        font-size: 2rem;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: var(--red);
    }

    .ulClass {
        list-style: none;

        li {
            margin-bottom: 0;
            margin-top: 0;
        }
        li::before {
            content: "•";
            color: var(--red);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-column-gap: 32px;
        margin: 64px auto;
    }
}

.buttonClass {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-top: 32px;
}

.listGrid {
    display: grid;

    .marginMe {
        margin-top: 0;
        margin-bottom: 0;
    }

    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}
`

const RoomsPart = () => (
<>
  <Wrapper>
      <div className="innerWrapper">
            <div className="col">
            <p className="styledP">Rooms Amenities</p>
            <div className="listGrid">
                <ul className="ulClass marginMe">
                    <li>Free Wi-Fi</li>
                    <li>Free Cable TV</li>
                    <li>USB &amp; Power Station</li>
                    <li>BBQ Area</li>
                </ul>

                <ul className="ulClass marginMe">
                    <li>Fire Pit</li>
                    <li>Gazebo</li>
                    <li>Outdoor Dining</li>
                    <li>Large Raised Patio area</li>
                    <li>Paved parking</li>
                </ul>
            </div>
            <Link to="/gallery" className="buttonClass" title="our gallery">
                <Button cta="OUR GALLERY" />
            </Link>
            </div>
            <div className="col minusOneRow">              
                <StaticImage
                src="../../components/home/bedroom.jpg"
                alt="bedroom image placeholder"
                // aspectRatio={16 / 9}
                layout="fullWidth"
                quality={100} formats={["AUTO", "WEBP", "AVIF"]} loading="lazy"
            />
            </div>
        </div>
    </Wrapper>
</>
)

export default RoomsPart
