import * as React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  background: var(--grey);

  .innerWrapper {
    max-width: var(--site-width);
    margin: 0 auto;
    padding: 64px 0;
    display: grid;
    grid-row-gap: 32px;

    h2 {
      color: var(--blue);
      font-size: 2rem;
      text-decoration: underline;
      text-decoration-color: var(--red);
    }

    .fullCol {
      grid-column: 1 / -1;
    }

    .ulClass {
      list-style: none;

      li {
        margin-bottom: 0;
        margin-top: 0;
      }
      li::before {
        content: '•';
        color: var(--red);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }

    @media (min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
      // align-items: center;
      grid-column-gap: 32px;
    }
  }
`

const NewRenosPart = () => (
  <>
    <Wrapper>
      <div className="innerWrapper">
        <div className="col fullCol">
          <h2>New Renovations and Improvements</h2>
        </div>
        <div className="col">
          <ul className="ulClass">
            <li>Best Mattress in the village</li>
            <li>Thick High-Density Gel Foam topper with 1200 Pocket Coils</li>
            <li>Extended patio in front of all the rooms</li>
            <li>Brand new patio sets for each room</li>
            <li>New Commercial WIFi System</li>
            <li>New Cedar Gazebo</li>
            <li>New Fire Pit</li>
          </ul>
        </div>
        <div className="col">
          <ul className="ulClass">
            <li>New BBQ area</li>
            <li>Fully Renovated Rooms</li>
            <li>All New Fixtures &amp; Room Furniture</li>
            <li>New Common Room</li>
            <li>Lounge area with full kitchen</li>
            <li>Continental breakfast</li>
            <li>Additional seating &amp; patio heaters</li>
          </ul>
        </div>
        <div className="col fullCol">
          <h2>Coming Soon</h2>
        </div>
        <div className="col">
          <li>EV Charging Stations</li>
        </div>
      </div>
    </Wrapper>
  </>
)

export default NewRenosPart
