import * as React from 'react'
// import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'

const HeroText = styled.div`
  display: grid;

  justify-content: center;
  align-items: center;

  margin-top: 64px;
  margin-bottom: 64px;

  .innerHeroWrapper {
    max-width: var(--site-width);
    margin: 0 auto;
  }

  .styledHTwo {
    text-align: center;
    font-weight: bold;
    color: var(--dark-blue);
    text-decoration: underline;
    text-decoration-color: var(--red);

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
`

const CanadianRockiesPart = () => (
  <>
    <div style={{ display: 'grid' }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: '1/1',
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 590,
        }}
        layout="fullWidth"
        // You can optionally force an aspect ratio for the generated image
        // aspectRatio={16 / 9}
        objectFit="center"
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src={'../../components/home/rockies_faded.jpg'}
        formats={['auto', 'webp', 'avif']}
        loading="eager"
        placeholder="tracedSVG"
      />

      {/* add an opacity with this row if needed */}
      {/* <div
				style={{
					// By using the same grid area for both, they are stacked on top of each other
					gridArea: "1/1",
					position: "relative",
					// This centers the other elements inside the hero component
					placeItems: "center",
					display: "grid",
					background: "rgba(0, 0, 0, 0.4)"
				}}
			></div> */}
      {/* add an opacity END */}

      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: '1/1',
          position: 'relative',
          // This centers the other elements inside the hero component
          placeItems: 'center',
          display: 'grid',
        }}
      >
        {/* Any content here will be centered in the component, just modify the Greyed Out HeroText */}
        <HeroText>
          <div className="innerHeroWrapper">
            <h2 className="styledHTwo">The warm side of the Canadian Rockies</h2>
            <p>
              The beautiful tourist mecca of Radium Hot Springs, BC, Canada. Being one of BC's best mountain getaway
              villages, Radium Hot Springs offers many outdoor activities, including golfing, skiing, snowboarding,
              outdoor ice skating, hiking, and mountain biking, natural hot spring pools, and warm freshwater lakes.
              Beautiful alpine scenery abounds in the region.
            </p>
            <p>
              This beautiful village is at the south entrance of Kootenay National Park, just a short 3-hour drive from
              Calgary, Alberta. Whether you like golf and the outdoors, or relaxing with great local restaurants, pubs,
              and shops, this mountain village has something to offer everyone.
            </p>
            <p>
              After a leisurely drive on the Banff-Windermere highway through the awe-inspiring Kootenay National Park,
              you will experience the world-famous Radium Hot Springs Mineral Pools. Natural rock walls and amazing
              scenery surround these odourless hot pools.
            </p>
            <p>
              Just a short drive along the highway and you will be welcomed at our cozy Radium Hot Springs INN, where
              you'll be able to relax after a day of exploring or shopping by soaking and swimming at the Hot Springs.
              The unique region is yours to discover, with all the amenities and features of a resort yet the quaint
              charm of a small village.
            </p>
            <p>
              Bighorn sheep, mule and whitetail deer, cougars, bears, elk &amp; wild turkeys are seen throughout the
              Columbia Valley and the national parks. We often see wildlife roaming through the village!
            </p>
            <p>Thank you for visiting!</p>
          </div>
        </HeroText>
      </div>
    </div>
  </>
)

export default CanadianRockiesPart
