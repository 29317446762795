import * as React from "react"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
max-width: var(--site-width);
margin: 64px auto;

.innerWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;

    .plusOneRow {
        grid-row: 2;
    }

    h2 {
        font-size: 3rem;
        text-decoration: underline;
        text-decoration-color: var(--red);
    }

    .styledP {
        font-size: 2rem;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: var(--red);
    }

    .ulClass {
        list-style: none;

        li {
            margin-bottom: 0;
            margin-top: 0;
        }
        li::before {
            content: "•";
            color: var(--red);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-column-gap: 32px;

        .plusOneRow {
            grid-row: 1;
        }
    }
}
`

const RatesPart = () => (
    <>
        <Wrapper>
            <div className="innerWrapper">
                <div className="col plusOneRow">
                    <p className="styledP">Bed Options for Rooms</p>
                    <p>We have 22 beautiful rooms that offer the comforts of home without any of the daily stress.</p>
                    <ul className="ulClass">
                        <li>Two Queen size beds</li>
                        <li>King Size bed plus 2 twin size bed</li>
                        <li>Two Queen size beds plus kitchenette Room</li>
                        <li>* 6 designated pet friendly rooms</li>
                    </ul>
                    <p><em><small>* maximum pet weight is 45lbs, must call to book, additional charge of &#36;25 per night.</small></em></p>
                </div>
                <div className="col">
                    <StaticImage
                        src="../../components/home/bathroom.jpg"
                        alt="bathroom image placeholder"
                        // aspectRatio={16 / 9}
                        layout="fullWidth"
                        quality={100} formats={["AUTO", "WEBP", "AVIF"]} loading="lazy"
                    />
                </div>
            </div>
        </Wrapper>
    </>
)

export default RatesPart
